

body {
  background-color: rgb(39, 39, 39);
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
}


a {
  text-decoration: none; /* Remove o sublinhado */
  color: inherit; /* Mantém a cor do texto padrão */
  cursor: pointer; /* Define o cursor para parecer um link */
}

#root {
  margin: 0 auto;
  padding: 0;

  min-height: 100vh;
  min-width: 100vw;
}



main {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.header-container {
  display:flex;
  align-items: center;
  justify-content: space-between ;
  width: 100%;
  padding: 24px;
  font-family: sans-serif
}

.menu-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  color: white;
}

a {
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;


  &:hover {
    color: #8a2be3;
}
}

button {
  border-radius: 30px;
}




.glow-on-hover {
    width: 160px;
    height: 50px;
    border: none;
    color: #ffffff;
    background: #000000;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 400;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 100px;
    font-size: 16px;
}

.glow-on-hover:active {
    color: #ffffff
}

.glow-on-hover:active:after {
    text-decoration: 0;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 100px;
    font-size: 16px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.logo {
  width: 150px; /* Defina a largura desejada */
  height: 100px; /* Defina a altura desejada */
  border-radius: 30px; /* Se você deseja bordas arredondadas */
  cursor: pointer;
}

.logo:hover {
  color: #000000;
}

.my-element { 
  align-items: center;
  justify-content: center;
  margin: 30px;
  background: #ffffff;
  border-radius: 15px;
  grid-template-columns: 120px 120px 120px;
  
  color: #000000;
  display: grid;
  gap: 220px;
}

.box1 {


  align-items: center;


}

h1 span {
 font-size: 20px;
}


.box3 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 200px;
  height: 200px; /* Certifique-se de definir largura e altura iguais para que a imagem fique perfeitamente circular */
  overflow: hidden; /* Adicione overflow: hidden para cortar a imagem que estiver fora do círculo */
}

.box3 img {
  max-width: 100%; /* Para garantir que a imagem não ultrapasse o círculo */
  height: auto;
  border-radius: 50%;
}

.who {
  
}